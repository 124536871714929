<template>
    <v-select
        hide-details dense outlined
        :label="label"
        :ref="name"
        :value="value"
        :items="selectOptions"
        item-text="name"
        item-value="name"
        :prefix="prefix[format]"
        :suffix="suffix[format]"
        @input="$emit('changed', $event)"
    ></v-select>
</template>
<script>
export default {
    name: 'ListSelect',
    props: ['label', 'format', 'name', 'value', 'options'],
    data: () => ({
        prefix: { 'default': '', 'number': '', 'date': '', 'decimal': '', 'euro': '', 'proz': '' },
        suffix: { 'default': '', 'number': '', 'date': '', 'decimal': '', 'euro': '€', 'proz': '%' },
    }),
    computed: {
        selectOptions() {
            return JSON.parse(this.options)
        },
    },
    methods: {
    },
}
</script>
<style scoped>
</style>